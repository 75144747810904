import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { useAuth } from "./app/auth";
import initializeApp from "./app/init";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { onValue, ref as rtdbRef } from "firebase/database";
import { H } from "highlight.run";
import { Buffer } from "buffer";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));

// Initializing different libraries
initializeApp();

function App() {
  // Check for login and initialize axios
  const auth = useAuth();
  //eslint-disable-next-line
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth?.user?.["screenName"] || auth?.user?.["displayName"]) {
      listenToSubscriptionData();
    }

    // eslint-disable-next-line
  }, [auth?.user]);

  const listenToSubscriptionData = async () => {
    //listen to the subscription data from realtime user database
    if (auth && auth.user) {
      const subscriptionRef = rtdbRef(
        auth?.rtdb,
        "subscription/" + auth?.user.localId
      );
      onValue(subscriptionRef, (snapshot) => {
        const data = snapshot.val();
        printDataSize(data);
        setSubscriptionData(data);

        if (!data) {
          const getSubscriptionInfo = httpsCallable(
            auth?.functions,
            "getSubscriptionInfo"
          );
          getSubscriptionInfo();
        } else {
          const date = new Date(data["createdAt"]);
          const createdAt =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
          H.identify(
            auth?.user?.["screenName"] || auth?.user?.["displayName"],
            {
              firebaseId: auth?.user?.["localId"],
              status: data["status"],
              createdAt: createdAt,
            }
          );

          if (data.status === "Lite Plan" && !data.renewDateAt) {
            //first time subcribing for lite plan
            //set the limit and set the renew

            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );

            const today = new Date();

            const renewalDate = new Date(today);
            renewalDate.setDate(today.getDate() + 30);
            //timestam
            updateSubscriptionInfo({
              renewDateAt: renewalDate.getTime(),
              credits: 30,
            });
          } else if (
            data.status === "Lite Plan" &&
            data.renewDateAt <= new Date().getTime()
          ) {
            const renewalDate = new Date(data.renewDateAt);
            renewalDate.setDate(renewalDate.getDate() + 30);
            //push renewDateAt 30 more dates
            //reset the limit
            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );
            updateSubscriptionInfo({
              renewDateAt: renewalDate.getTime(),
              credits: 30,
            });
          } else if (data.status === "Hobbyist Plan" && !data.renewDateAt) {
            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );

            const today = new Date();

            const renewalDate = new Date(today);
            renewalDate.setDate(today.getDate() + 30);
            updateSubscriptionInfo({
              renewDateAt: renewalDate.getTime(),
              credits: 10,
            });
          } else if (
            data.status === "Hobbyist Plan" &&
            data.renewDateAt <= new Date().getTime()
          ) {
            const renewalDate = new Date(data.renewDateAt);
            renewalDate.setDate(renewalDate.getDate() + 30);
            //push renewDateAt 30 more dates
            //reset the limit
            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );
            updateSubscriptionInfo({
              renewDateAt: renewalDate.getTime(),
              credits: 10,
            });
          }
        }
      });
    }
  };

  const printDataSize = (data) => {
    let dataJSON = JSON.stringify(data);
    let dataJSONSize = Buffer.byteLength(dataJSON, "utf8"); //convert to kb
    dataJSONSize = dataJSONSize / 1024;
    console.log("<strong> Data Size App.js: " + dataJSONSize + " kb</strong>");
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          {/* <Route path="/auto-generate" element={} /> */}
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route
            path="*"
            element={
              <Navigate to={auth?.user ? "/app/welcome" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
